#carouselExampleIndicators {
    /* background-color: rgb(28, 84, 117);
     */
    background-position: center;
    background-size: cover;
    padding: 4rem;
}

#carouselExampleIndicators .carousel-item {
    padding-left: 1rem;
    padding-right: 1rem;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

@media screen and (max-width: 600px) {
    #carouselExampleIndicators {
        padding: 0.5rem;
    }

}