.navigation-logo {
    font-family: 'Handlee', cursive;
    font-weight: 900;
    color: rgb(28, 84, 117) !important;
    text-decoration: none;
    font-size: 2rem;
}

@media screen and (max-width: 992px) {
    .navigation-logo {       
        font-size: 1.3rem;
    }
}

.navbar-nav a {
    color: rgb(46, 46, 46) !important;
    transition: all 0.3s ease;
}

.navbar-nav a:hover {
    color: rgb(4, 106, 173) !important;
}

/* 
.navigation-brand{
    display: flex;
} */
.navbar-toggler {
    display: none;
}

.nav-link {
    font-size: 20px;
    display: flex;
    align-items: center;
}

.navigation-icon {
    width: 20px;
    height: 18px;
    margin-right: 2px;
}

@media screen and (max-width: 991px) {
    .navigation-brand {
        display: flex;
        justify-content: space-between
    }

    .navbar-toggler {
        display: flex;
        align-items: center;
    }
}