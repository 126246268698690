.hadith-player .audio-player-container {
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
}

.hadith-player .audio-player-container .hadith-text-container {
    height: calc(100vh - 250px);
    overflow-y: auto;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
}

.hadith-player .audio-player-container .hadith-text-container .hadith-text {
    font-size: 20px;
    text-align: justify;
    height: 100%;
}

@media only screen and (max-width: 600px) {
    .hadith-player .audio-player-container .hadith-text-container .hadith-text {
        font-size: 16px;       
    }
}

.hadith-player .audio-player-container .hadith-audio-container audio {
    width: 100%;
}

.hadith-player .hadith-list {}

.hadith-player .hadith-list ul {
    list-style: none;
    padding-left: 0;
}

.hadith-player .hadith-list ul li {
    padding: 5px;
}

.hadith-player .hadith-list ul li button {
    border: none;
    padding: 8px 30px;
    min-width: 200px;
    text-align: center;
    background-color: #1c81ce;
    color: white;
    transition: all 0.3s ease;
    border-radius: 7px;
}

.hadith-player .hadith-list ul li button:hover {
    background-color: #0f68ac;
}

.hadith-player .hadith-list ul li button.active {
    background-color: #0f3c5f;

}