.kitabs-title{
    font-weight: bold;
    text-align: center;
    border: 8px solid transparent;
    padding: 10px;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.kitabs .card{
    border: none;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: all 0.3s ease;
}
.kitabs .card:hover{
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.kitabs .card-footer{
    border: none;
    background-color: transparent;
}