.chapter-card .card{
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
    transition: all 0.3s ease;
}
.chapter-card .card:hover{
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}
.chapter-card .card-footer{
    background-color: transparent;
    border: none;
}