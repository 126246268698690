.footer{
    margin-top: 5rem;
    background-color: #041024;
    color: whitesmoke;
}
.footer ul{
    list-style: none;
    padding-left: 0;
}
.footer ul li a{
    text-decoration: none;
    color: rgb(223, 240, 255);
    font-size: 18px;
    transition: all 0.3s ease;
}
.footer ul li a:hover{
    color: rgb(133, 198, 255);
}

.footer a{
    text-decoration: none;
    color: rgb(255, 224, 196);
}